import React, { useState } from 'react';
import { AppShell, Box, Center, Container, Header, Navbar, Space } from '@mantine/core';
import { Link } from 'react-router-dom';
import { useMe } from '../../api/getUsers';


type AppShellPageProps = {
  children: JSX.Element,
};


const AppShellPage = ({ children }: AppShellPageProps) => {

  const me = useMe();

  return (<AppShell
      padding={0}
      //navbar={<Navbar width={{ base: 300 }} height={500} p="xs">{}</Navbar>}
      header={<Header height={40} p="xs">{
        <nav>
          <div style={{ display: 'flex' }}>
            <Link to="/">Hem</Link>
            <Space w={'sm'}/>
            <Link to="/vatten">Vatten</Link>
          </div>
        </nav>
      }</Header>}
      sx={(theme) => ({} )}
      styles={(theme) => ({})}
    >
      <Center>
        {children}
      </Center>
    </AppShell>
  );
}

export default AppShellPage;
