import React, { useEffect, useState } from 'react';
import {
  Anchor,
  Box,
  Button,
  Card, CardSection,
  Checkbox,
  CheckboxGroup,
  Container,
  Grid,
  Group,
  Image,
  Modal,
  NumberInput,
  Space,
  Text,
  TextInput,
  useMantineTheme
} from '@mantine/core';
import { Calendar } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { useMe } from '../../api/getUsers';
import { Booking } from 'egnahem-api';
import { useCreateBooking } from '../../api/createBooking';


type HomePageProps = {
  id?: string,
};

type BookingForm = {
  firstname: string,
  lastname: string,
  address: string,
  phoneNumber: string,
  startDate: Date | undefined,
  startTime: Date | undefined,
  preferredPickupTime: string | undefined,
  termsOfService: Array<string>
  bookingLengthInDays: number
};

const HomePage = ({ id }: HomePageProps) => {

  const createBooking = useCreateBooking();

  const meUser = useMe();

  const [validatedBooking, setValidatedBooking] = useState<Booking | undefined>(undefined);
  const [isConfirmOpened, setConfirmOpened] = useState(false);

  useEffect(() => {
    if (meUser.isSuccess) {
      form.setFieldValue('firstname', meUser?.data?.firstName || '');
      form.setFieldValue('lastname', meUser?.data?.lastName || '');
      form.setFieldValue('address', meUser?.data?.address || '');
      form.setFieldValue('phoneNumber', meUser?.data?.phoneNumber || '');
      console.log(meUser)
    }
  }, [meUser.status])

  const form = useForm<BookingForm>({
    initialValues: {
      firstname: '',
      lastname: '',
      address: '',
      phoneNumber: '',
      startDate: undefined,
      startTime: undefined,
      preferredPickupTime: undefined,
      termsOfService: [],
      bookingLengthInDays: 1,
    },

    validate: {
      startDate: (value) => value ? null : 'Välj vilken dag du vill hämta släpet',
      //startTime: (value) => value ? null : 'Välj vilken tid du vill hämta släpet',
      termsOfService: (value) => value.length < 2 ? 'Du måste acceptera' : null,
      preferredPickupTime: (value) => {
        form.setFieldValue('preferredPickupTime', value?.trim())
        return null;
      }
    },
  });

  const theme = useMantineTheme();

  const getDefaultPreferredPickupTime = (): string => {
    return form.values.startDate?.toDateString() == new Date().toDateString() ? "Så fort som möjligt" : "Kl 10:00 samma dag";
  }

  return (
    <Box sx={{ maxWidth: 320}} >
      <Box mx="auto">
        <form onSubmit={form.onSubmit((values) => {

          if (!values.startDate) {
            return
          }

          const booking = new Booking();
          booking.sku = 'trailer-1';

          if (meUser.data && meUser.data.userId) {
            booking.bookedForUser = meUser.data.userId;
          }

          const startDateWithoutTime = new Date(values.startDate.toDateString());
          const startDateInMillis = startDateWithoutTime.getTime();
          const startTimeInMillis = (8 * 3600 * 1000) // 08:00

          const startDateTimeInMillis = startTimeInMillis + startDateInMillis;

          booking.preferredPickupTime = values.preferredPickupTime || getDefaultPreferredPickupTime();

          booking.startDateTime = new Date(startDateTimeInMillis);
          booking.endDateTime = new Date(startDateTimeInMillis + 24 * 3600 * 1000 - 1);

          console.log(values)
          console.log(booking)

          setValidatedBooking(booking);
          setConfirmOpened(true);
        })}>
          <Box hidden={true}>
            <TextInput
              placeholder="förnamn"
              label="Förnamn"
              required
              {...form.getInputProps('firstname')}
            />
            <TextInput
              placeholder="efternamn"
              label="Efternamn"
              required
              {...form.getInputProps('lastname')}
            />
            <TextInput
              placeholder="Gatunamn 1"
              label="Adress"
              required
              {...form.getInputProps('address')}
            />
            <TextInput
              placeholder="0701234567"
              label="Telefonnummer"
              required
              {...form.getInputProps('phoneNumber')}
            />
          </Box>

          <Card withBorder={true} >
            <Card.Section>
            <Grid grow align={'center'} gutter="md">
              <Grid.Col span={2}>
                <Image
                  sx={(theme) => ({marginLeft: theme.spacing.md})}
                  radius="md"
                  width={60}
                  height={60}
                  fit="contain"
                  src="trailer-wt-250.jpg"
                  alt="Släpvagn WT Trailer Standard 250"
                />
              </Grid.Col>
              <Grid.Col span={8} >
                <Text weight="bold" size="lg">Släpvagn</Text>
                <Text>WT Standard 250</Text>
              </Grid.Col>
            </Grid>
            </Card.Section>
          </Card>
          <Space h={theme.spacing.xs}/>
          <Card withBorder={true}>
            <Calendar
              //size={"md"}
              dayStyle={(date) => {
                const day = date
                const today = new Date();
                const selected = form.values.startDate;
                if (new Date(day.toDateString()) < new Date(today.toDateString())) {
                  return { backgroundColor: theme.colors.gray[2], color: theme.colors.gray[5], margin: "1px" }
                } else if (day.toDateString() == new Date("2023-01-01T00:00:00").toDateString()) {
                  return { backgroundColor: theme.colors.gray[7], color: theme.white, margin: "1px" }
                } else if (day.toDateString() == selected?.toDateString()) {
                  return { backgroundColor: theme.colors.blue[7], color: theme.white, margin: "1px" }
                } else {
                  return { backgroundColor: theme.colors.green[6], color: theme.white, margin: "1px" }
                }
              }}
              excludeDate={(date) => {
                return date.toDateString() == new Date("2023-01-01T00:00:00").toDateString()
                  || (new Date(date.toDateString()) < new Date(new Date().toDateString()))
              }}
              {...form.getInputProps('startDate')}
            />
            <Space h={theme.spacing.md}/>
            <Box>
              {form.values.startDate ?
                <Text sx={(theme) => ({
                  color: theme.colors.gray[7],
                  fontSize: theme.fontSizes.xs,
                  fontStyle: 'italic'
                })}>
                  Bokningen gäller från kl 8:00 och ett dygn framåt.<br/>
                </Text>
                :
                <Text sx={(theme) => ({
                  color: theme.colors.gray[7],
                  fontSize: theme.fontSizes.xs,
                  fontStyle: 'italic'
                })}>
                  Bokningen gäller från kl 8:00 och ett dygn framåt.<br/>
                </Text>
              }
            </Box>
            <Space h={theme.spacing.md}/>
            <TextInput
              onBlur={() => {
                form.setFieldValue('preferredPickupTime', form.values.preferredPickupTime?.trim())
              }}
              placeholder={getDefaultPreferredPickupTime()}
              label="När föredrar du att hämta nyckeln?"
              {...form.getInputProps('preferredPickupTime')}
            />
            <Box hidden={true}>
              <NumberInput
                label="Antal dagar"
                required
                {...form.getInputProps('bookingLengthInDays')}
              />
            </Box>
            <Space h={theme.spacing.md}/>
            <CheckboxGroup
              spacing={theme.spacing.xs}
              defaultValue={[]}
              {...form.getInputProps('termsOfService', { type: 'checkbox' })}
              required
            >
              <Checkbox
                value="insurrance"
                label="Jag har försäkring på min bil"
              />
              <Checkbox
                value="terms-of-service"
                label={
                  <>
                    Jag accepterar <Anchor size="sm" href="https://mantine.dev" target="_blank">
                    villkoren för släpvagnslån
                  </Anchor>
                  </>
                }
              />
            </CheckboxGroup>

            <Space h={theme.spacing.md}/>
            <Group position="right" mt="md">
              <Button type="submit">Till bekräfta</Button>
            </Group>
          </Card>
        </form>
      </Box>

      <Modal
        withCloseButton={false}
        opened={isConfirmOpened}
        onClose={() => {
          setConfirmOpened(false);
          setValidatedBooking(undefined);
        }}>
        <pre>{JSON.stringify(validatedBooking, null, 1)}</pre>
        {meUser.data?.firstName} {meUser.data?.lastName}
        {meUser.data?.address}
        <div>
          <br/>Önskas hämtas: {validatedBooking?.preferredPickupTime}
          <br/>Hämtas tidigast: {validatedBooking?.startDateTime.toLocaleString()}
          <br/>Lämnas senast: {validatedBooking?.endDateTime.toLocaleString()}
        </div>
        <Group position="right" mt="md">
          <Button variant="outline" sx={(theme) => ({})} onClick={() => {
            setConfirmOpened(false)
          }}>Stäng</Button>
          <Button data-autofocus onClick={() => {
            if (validatedBooking) {
              createBooking.mutate(validatedBooking, {
                onSuccess: (resp) => {
                  console.log("Success!");
                  setConfirmOpened(false);
                }
              });
            }
          }}>Bekräfta bokning</Button>
        </Group>
      </Modal>

    </Box>
  );
}

export default HomePage;
