import { useMutation } from 'react-query';
import { egnahemApi } from './apiConfig';
import {Booking} from 'egnahem-api'

const createBooking = (booking : Booking) => {
  return egnahemApi.createBooking(booking);
}

export const useCreateBooking = () => {
  return useMutation(createBooking, {});
};
