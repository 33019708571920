import { ResponseContext, RequestContext, HttpFile } from '../http/http';
import * as models from '../models/all';
import { Configuration} from '../configuration'

import { Booking } from '../models/Booking';
import { Hello } from '../models/Hello';
import { User } from '../models/User';
import { ObservableDefaultApi } from './ObservableAPI';

import { DefaultApiRequestFactory, DefaultApiResponseProcessor} from "../apis/DefaultApi";
export class PromiseDefaultApi {
    private api: ObservableDefaultApi

    public constructor(
        configuration: Configuration,
        requestFactory?: DefaultApiRequestFactory,
        responseProcessor?: DefaultApiResponseProcessor
    ) {
        this.api = new ObservableDefaultApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param booking 
     */
    public createBooking(booking: Booking, _options?: Configuration): Promise<Booking> {
        const result = this.api.createBooking(booking, _options);
        return result.toPromise();
    }

    /**
     * @param sku 
     * @param fromDateTime 
     * @param toDateTime 
     */
    public getAllBookings(sku?: string, fromDateTime?: Date, toDateTime?: Date, _options?: Configuration): Promise<Array<Booking>> {
        const result = this.api.getAllBookings(sku, fromDateTime, toDateTime, _options);
        return result.toPromise();
    }

    /**
     */
    public getMe(_options?: Configuration): Promise<User> {
        const result = this.api.getMe(_options);
        return result.toPromise();
    }


}



