import React from 'react';
import { BrowserRouter, BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AppShellWrapper from './components/AppShellWrapper';
import { QueryClientProvider, QueryClient } from 'react-query';
import TrailerPage from './pages/TrailerPage';
import WaterPage from './pages/WaterPage';
import HomePage from './pages/HomePage';

export default function App() {

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <div>
          <Routes>
            <Route path="/trailer" element={<AppShellWrapper><TrailerPage/></AppShellWrapper>}/>
            <Route path="/vatten" element={<AppShellWrapper><WaterPage id={"water"}/></AppShellWrapper>}/>
            <Route path="/garden" element={<AppShellWrapper><Garden/></AppShellWrapper>}/>
            <Route path="/" element={<AppShellWrapper><HomePage id={"home"}/></AppShellWrapper>}/>
          </Routes>
        </div>
      </Router>
    </QueryClientProvider>
  );
}

function Garden() {
  return <h2>Trädgård</h2>;
}

