import React from 'react';
import { BackgroundImage, Center, Container, Image, Space } from '@mantine/core';


type HomePageProps = {
  id: string,
};


const WaterPage = ({ id }: HomePageProps) => {

  return (
    <Container sx={(theme) => ({
      marginTop: "0px",
      width: '100%',
    })}>
      <BackgroundImage src={"header-dark.jpg"}>
        <Container sx={(theme) => ({
          width: '100%',
          minHeight: '140px',
        })}>
          <Center style={{ height: 140, color: "white" }}>

            <h1>Kortedala vattendistribution</h1>

          </Center>
        </Container>
      </BackgroundImage>

      <Container sx={(theme) => ({ paddingLeft: theme.spacing.md })}>

      <p>
        Välkommen hit. Hemsidan för vattenföreningen i Egnahemsområdet i Kortedala – <i>Kortedala Vattendistribution.</i>
      </p>
      <p>
        Här kommer vi att lägga upp information som är relevant för er som är medlemmar i föreningen.
        Tips och praktiska råd varvat med föreningsinfo. I Kalendariet hittar du kommande möten och
        händelser som är bra att hålla koll på.
      </p>
      <p>
        Till en början har vi endast lagt upp det som ligger närmast i tiden, nämligen vårt öppna
        informationsmöte inför föreningsstämman senare i vår. På föreningsstämman kommer vi att
        behandla förslag på ny kostnadsfördelning och förslag på nya stadgar. Det är två större
        förändringar som kommer att påverka hur föreningen arbetar.   Anledningen att vi kallar till
        ett öppet informationsmöte är att ge er medlemmar möjlighet att påverka och få insyn i hur vi
        i styrelsen har resonerat. Läs igenom materialet och hör gärna av er till ordförande Fredrik Rauer
        eller kassör Alexander Sjöberg om ni har frågor.
      </p>

        <Space h={"md"}/>

        <h3>Föreningsstämma (årsmöte)</h3>
        <p>
          Kortedala församling, Årstidsgatan 1<br/>
          11:e Mars kl 15:00<br/>
        </p>
        <p>
          <i>Mötesdokument</i><br/>
          <a href={"vatten/forslag-fran-styrelsen-arstamma-2023-03-11-3.pdf"}>Förslag från styrelsen</a><br/>
          <a href={"vatten/kallelse-arstamma-2023-03-11.pdf"}>Kallelse till föreningsstämman</a><br/>
          <a href={"vatten/verksamhetsberattelse-2022.pdf"}>Verksamhetsberättelse för 2022</a><br/>
          <a href={"vatten/arsredovisning-2022-3.pdf"}>Årsredovisning för 2022</a><br/>
          <a href={"vatten/protokoll-arsmate-2022-03-22.pdf"}>Protokoll årsmöte 2022</a><br/>
          <a href={"vatten/protokoll-extra-arsmate-2022-06-02.pdf"}>Protokoll extra årsmöte 2022</a><br/>
        </p>

      </Container>
      <Space h={'xl'}/>


    </Container>
  );
}

export default WaterPage;
