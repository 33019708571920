import { useQuery } from 'react-query';
import { User } from 'egnahem-api';
import { egnahemApi } from './apiConfig';

const getMe = (): Promise<User> => {
  return egnahemApi.getMe();
};

export const useMe = () => {
  return useQuery<User, Error>({
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    queryKey: ['me'],
    queryFn: () => getMe(),
    placeholderData: undefined
  });
};
