import React from 'react';
import { BackgroundImage, Card, Center, Container, Image, Overlay, Space } from '@mantine/core';
import {Link} from "react-router-dom";


type HomePageProps = {
  id: string,
};


const HomePage = ({ id }: HomePageProps) => {

  return (
    <Container sx={(theme) => ({
      marginTop: "0px",
      width: '100%',
    })}>

      <BackgroundImage src={"header-dark.jpg"} >
        <Container sx={(theme) => ({
          width: '100%',
          minHeight: '140px',
        })}>
          <Center style={{ height: 140, color: "white"}}>
            <h1>Kortedala egnahemsförening</h1>
          </Center>
        </Container>
      </BackgroundImage>

      <Container sx={(theme) => ({ paddingLeft: theme.spacing.md })}>

        <Space h={"xl"}/>
        
        <h2>Hej och välkomna till Kortedala Egnahemsförening!</h2>

        <p>
          Det är vi som bor i egnahemsområdet som tillsammans driver Kortedala Egnahemsförening.
          Målsättningen är att skapa och utveckla möjligheter till trivsel, gemenskap och ett hållbart
          boende i området. För att skapa en miljö där alla kan trivas är det viktigt att alla, på den nivå
          de kan, engagerar sig i att göra det bra. Om det så är genom att delta i våra fixardagar, sitta i
          styrelsen, skicka in motioner och idéer, delta i arbetsgrupper eller ta hand om vår natur.
        </p>

        <p>
          <b>Men först lite historia</b><br/>
          Egnahemsrörelsen uppstod redan i slutet av 1800-talet, som ett svar på trångboddheten.
          Stora områden köptes upp och styckades till villatomter. Sammanfattningsvis var det
          grundläggande syftet med Egnahem att även “vanliga” arbetarfamiljer skulle kunna ha råd
          med eget hus och trädgård.
          Kortedala Egnahemsområde upprättades runt åren 1956 och 1959 och sedan dess har
          många familjer flyttat in och ut från de 127 hus som står här. Men det finns några
          fastighetsägare kvar hos oss som varit med sedan begynnelsen, visst är det häftigt?
        </p>

        <p>
          <b>Vad är Kortedala Egnahemsområde?</b><br/>
          Vi har vacker natur som omsluter vårt fina bostadsområde. En del sköter Göteborgs stad
          om, men andra delar står våra ideella krafter för. Det finns medlemmar som tagit sig an att
          sköta om området vid deras hus, och under sommaren har vi anställt sommarjobbare att
          sköta om vår grönytor.
          Vi har ett populärt aktivitetsområde längst ner på Morgongatan. Där finns en lekplats,
          basketplan, fotbollsplan och bandymål. Det är vårt att förvalta och därför är det viktigt att alla
          medlemmar vårdar det väl och ser till att alla trivs där. Vi rustar upp när något är trasigt och
          håller det i gott skick. Vi vill att alla barn, vuxna och unga ska se området som en trygg plats.
        </p>

        <b>För att alla ska kunna trivas är det viktigt att ta hand om varandra och det som är vårt.
          Tänk därför på att:</b>
        <ul>
          <li>Inte slänga skräp och avfall i naturen (det gäller även trädgårdsavfall – för detta har
            föreningen fyra kärl utplacerade i området).</li>
          <li>Alla får vistas på lekplatsområdet, men ta hänsyn till de hus som ligger bredvid
            genom att inte leka högljutt sena kvällar och nätter.</li>
          <li>Lämna våra grönområden, idrottsplaner och lekplatsen i samma skick som när ni
            kom dit.</li>
          <li>Hålla ner hastigheten när ni kör inne på området – barn och djur är mjuka, bilar är
            hårda.</li>
          <li>Plocka upp efter er. Om en sopbehållare är full, ta med ditt skräp hem igen och
            kontakta Göteborgs stad om tömning.</li>
          <li>Det finns några gästparkeringar i området, använd gärna dem och undvik att ställa
            bilar längs gatan eftersom de lätt kan stå i vägen. Det finns en tidsbegränsning på tio
            minuter för parkering på gatan. Efter det riskerar ni att få böter.</li>
          <li>Fastighetsägare med hörntomt måste se till att häckarna inte överskrider 80
            centimeter på höjden, mätt från gatunivå.</li>
        </ul>

        <p>
          <b>Styrelsen</b><br/>
          Varje år i mars hålls ett årsmöte för alla medlemmar i föreningen. Då väljs också en styrelse.
          Och vem kan vara med i den då? Alla som vill. Ju fler som är med och engagerar sig, desto
          bättre och mer demokratisk blir föreningen. Kontakta valberedningen om du är intresserad.
          Styrelsen skickar regelbundet ut information, bland annat i form av nyhetsbrev, i alla
          brevlådor, om vad som händer och sker i området. Förutom nyhetsbreven har vi även en
          Facebookgrupp som alla medlemmar kan ansluta sig till (sök på Kortedala
          Egnahemsförening) där vi publicerar information och påminnelser. Dessutom har vi en
          gedigen anslagstavla i korsningen Morgongatan och Julaftonsgatan. På så sätt hoppas vi att
          alla ska kunna hålla sig uppdaterade.
          Två gånger om året anordnas fixar- och städdagar där vi med gemensamma krafter ser över
          vårt område. Vi städar, röjer, renoverar, bygger och planterar. Förutom att det så klart är
          roligt och viktigt att så många som möjligt deltar i den mån de kan, är det också ett bra sätt
          att träffa sina grannar på. Det är också då många nya idéer föds om vad som behövs göras.
        </p>

        <p>
          <b>Medlemsavgift – varför ska jag betala?</b><br/>
          Nya stadgar röstades igenom 2020 (och uppdaterades 2023) och då beslutades också att
          som egnahemsägare är alla fastighetsägare skyldiga att vara medlemmar i föreningen.
          Medlemsavgiften betalas en gång om året i maj och är just nu på 500 kronor. Alla hushåll får
          ett utskick om detta i brevlådan när det är dags och hur det går till.
          Medlemsavgifterna används för att förvalta och utveckla våra gemensamma intressen,
          fastigheter och ytor. Förutom rena fasta avgifter som avfallshämtning och arrendeavgift till
          Göteborgs stad, har vi även använt kassan för inköp av till exempel nya fotbollsmål, växter
          eller byggmaterial vid upprustning av bänkarna. Dessutom har vi gemensamma verktyg och
          maskiner samt ett släp, som kräver kontinuerligt underhåll.
          Det finns ingen begränsning på vad vi kan hitta på i vårt område. Allt beror på hur mycket tid,
          engagemang och kraft som du vill lägga ner.
          Styrelsen välkomnar alla tankar och idéer som kan lyfta vårt område, kom med dem till
          brevlådan på Gryningsgatan 16 så hjälps vi åt.
        </p>

        <p>
          <b>Vill du veta mer?</b><br/>
          Kontakta styrelsen på kortedalaegnahemsforening@gmail.com.
        </p>

        <p>
          Vi ses!<br/>
          <i>Styrelsen för Kortedala Egnahemsförening</i>
        </p>

        <Space h={"xl"}/>
        <Space h={"xl"}/>

        <p>
          <b>Har du frågor om garage och parkering?</b><br/>
          Kontakta garageföreningen: Jimmy Aradi 070-467 08 49<br/>
          <b>Har du frågor gällande vårt gemensamma vatten?</b><br/>
          Kontakta <Link to='/vatten'>vattenföreningen</Link> kortedalavatten@gmail.com
        </p>

        <Space h={"md"}/>


      </Container>

    </Container>

  );
}

export default HomePage;
