/**
 * Api
 * Api
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class Booking {
    'id'?: string;
    'sku': string;
    'bookedForUser': string;
    'startDateTime': Date;
    'endDateTime': Date;
    'preferredPickupTime'?: string;
    'createdAt'?: Date;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "sku",
            "baseName": "sku",
            "type": "string",
            "format": ""
        },
        {
            "name": "bookedForUser",
            "baseName": "bookedForUser",
            "type": "string",
            "format": ""
        },
        {
            "name": "startDateTime",
            "baseName": "startDateTime",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "endDateTime",
            "baseName": "endDateTime",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "preferredPickupTime",
            "baseName": "preferredPickupTime",
            "type": "string",
            "format": ""
        },
        {
            "name": "createdAt",
            "baseName": "createdAt",
            "type": "Date",
            "format": "date-time"
        }    ];

    static getAttributeTypeMap() {
        return Booking.attributeTypeMap;
    }

    public constructor() {
    }
}

